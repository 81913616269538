import React, { Component } from 'react'
import DetailsView from './details'
import { List } from 'antd-mobile/es'
import { soil } from '../../../service'

export default class SoilsensorView extends Component {

    state = {
        open: false,
        imei: null,
        address: null,
        name: null,
        soilList: []
    }

    componentDidMount() {
        this.fetch()
    }

    fetch = async () => {
        const { data } = await soil.getList({ type: '土壤传感器' })
        this.setState({
            soilList: [...data],
            imei: data[0]['imei'],
            address: data[0]['address'],
            name: data[0]['name']
        })
    }



    render() {
        const { open, imei, address, name, soilList } = this.state
        return (
            <>
                <List style={{ marginBottom: '15px' }}>

                    {
                        soilList.length ? (
                            soilList.map(item => (
                                <List.Item
                                    key={item.imei}
                                    onClick={() => this.setState({ imei: item.imei, address: item.address, name: item.name, open: true })}
                                >
                                    {item.name}
                                </List.Item>
                            ))
                        ) : (
                            <div style={{ textAlign: 'center', padding: '10px' }}>暂无设备</div>
                        )
                    }
                </List>

                <DetailsView
                    imei={imei}
                    address={address}
                    name={name}
                    open={open}
                    onClose={() => this.setState({ open: false })}
                />
            </>

        )
    }
}

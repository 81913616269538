import request from "../request"
/**
 * ============================================================== 员工 ========================================================
 */
const getList = (queryParams) => request.get('/dev/getlist2', { params: { ...queryParams } })


const getSoilData = (queryParams) => request.get('/dev/lora/data', { params: { ...queryParams } })

const soil = {
    getSoilData,
    getList
}

export default soil
import React, { Component } from 'react'
import { Button, Selector, Grid, Space, Divider, Toast } from 'antd-mobile/es'
import { QuestionCircleOutline } from 'antd-mobile-icons'
import { seeding } from '../../../service'
import Timer from "../../../components/timer";
import './index.less'

import bg0 from '../../../assets/image/bg-c.jpg'
import bg1 from '../../../assets/image/bg-1.jpg'
import bg2 from '../../../assets/image/bg-2.jpg'
import bg3 from '../../../assets/image/bg-3.jpg'
import bg4 from '../../../assets/image/bg-4.jpg'
import bg5 from '../../../assets/image/bg-5.jpg'


export default class RiceseedView extends Component {

    state = {
        area: 0, // 分区
        options: [],
        allValve: [], //  所有阀门
        inverter: [], // 变频器
        imei: null, // 变频器imei
        imei_data: null, // 变频器imei
        imei_soil: null, // 变频器imei
        soils: [],
        record: {}, // 变频器信息
        qsb_state: {}, // 潜水泵状态
        loading: false,
        lora: null, // 土壤信息
    }

    componentDidMount() {
        this.fetch()
    }

    fetch = async () => {
        try {
            const { data } = await seeding.getlist()
            this.setState({
                options: [{ value: 0, label: '全区' }, ...data.map(item => ({ value: item.id, label: item.name }))],
                loading: true
            }, () => this.onHandleChange({ value: this.state.area }))
        } catch (error) {
            this.setState({ loading: false })
        }
    }

    // 选择分区
    onHandleChange = async ({ value }) => {
        try {
            const { soils } = this.state
            const soil = soils.find(item => item.region_id === value)
            if (soil) {
                const lora = await seeding.comandlora({ imei: soil.imei, address: soil.address })
                this.setState({
                    lora: lora.data,
                })
            }

            const { data } = await seeding.getDevice({ region_id: value || null })
            const soilList = await seeding.getDevice({ type: '土壤传感器' })

            this.setState({
                area: value,
                inverter: data.filter(item => item.type === "变频器"),
                allValve: data.filter(item => item.type === "阀门"),
                imei: data.find(item => item.flag === 'control')?.imei,
                imei_data: data.find(item => item.flag === 'data')?.imei,
                soils: soilList.data
            }, () => {
                this.getRecord(1000)
            })
        } catch (error) {
            this.setState({ loading: false })
        }
    }

    getDevs = async () => {
        const { data } = await seeding.getDevice()
        this.setState({ allValve: data.filter(item => item.type === "阀门") })
    }


    // 查询变频器信息
    getRecord = async (delay = 1000) => {
        try {
            const { imei, imei_data } = this.state
            await seeding.comandpl(imei_data)

            await new Promise(resolve => setTimeout(resolve, delay));

            await seeding.comandgdyl(imei_data)

            await new Promise(resolve => setTimeout(resolve, delay));

            await seeding.comandgqsb(imei)

            await new Promise(resolve => setTimeout(resolve, delay));

            const message = await seeding.comandmessage({ imei: imei_data })
            const state = await seeding.comandstate({ imei })
            this.setState({
                record: message.data,
                qsb_state: state.data.qsb_state,
            })
        } catch (error) {
            console.log(error);
        } finally {
            this.setState({ loading: false })
        }
    }

    // 开关操作
    changeStatus = async (imei, value) => {
        try {
            await seeding.comandctrl({ imei: imei, comand_name: value })
            Toast.show({
                icon: 'success',
                content: '操作成功',
            })
        } catch (error) {
            Toast.show({
                icon: 'fail',
                content: '操作失败',
            })
        }
    }
    // 阀门开
    open = async (imei, index) => {
        this.valvesDelay(index)
        try {
            await seeding.open(imei)
            Toast.show({
                icon: 'success',
                content: '操作成功',
            })
        } catch (error) {
            Toast.show({
                icon: 'fail',
                content: error.message,
            })
        }
    }
    // 阀门开
    half = async (imei) => {
        try {
            await seeding.half(imei)
            Toast.show({
                icon: 'success',
                content: '操作成功',
            })
        } catch (error) {
            Toast.show({
                icon: 'fail',
                content: '操作失败',
            })
        }
    }
    // 阀门关
    close = async (imei, index) => {
        this.valvesDelay(index)
        try {
            await seeding.close(imei)
            Toast.show({
                icon: 'success',
                content: '操作成功',
            })
        } catch (error) {
            Toast.show({
                icon: 'fail',
                content: '操作失败',
            })
        }
    }

    valvesDelay = (index) => {
        const { allValve } = this.state
        allValve[index].loading = true
        allValve.forEach(item => item.disabled = true)
        this.setState({
            allValve: allValve
        })
        setTimeout(async () => {
            allValve[index].loading = false
            allValve.forEach(item => item.disabled = false)
            this.setState({
                allValve: allValve
            }, () => this.getDevs())
        }, 1000 * 20);
    }

    render() {
        const { area, options, devices, inverter, allValve, record, qsb_state, loading, lora } = this.state
            , { onHandleChange, changeStatus, open, close, half } = this
        return (
            <div style={{ height: 'calc(100vh - 120px)', overflowY: 'auto' }}>
                <div className='bg-mulit'>
                    <img src={bg0} />
                </div>
                <Selector
                    columns={options.length}
                    style={{
                        '--border-radius': '100px',
                        '--border': 'solid transparent 1px',
                        '--checked-border': 'solid var(--adm-color-primary) 1px',
                        '--padding': '2px 6px',
                        padding: '0 6px'
                    }}
                    showCheckMark={false}
                    options={options}
                    defaultValue={[0]}
                    onChange={(value) => onHandleChange({ value: value[0] })}
                />
                <Divider />

                <Space direction='vertical' >
                    {
                        inverter.length ? (
                            <>
                                {
                                    inverter.filter(dev => dev.type === '变频器' && dev.name !== '数据模块').map(item => (
                                        <Grid className='grid-content' columns={12} gap={8} key={item.imei}>
                                            <Grid.Item span={4}>
                                                <Button color='default' fill='none'>
                                                    {item.name}:
                                                </Button>
                                            </Grid.Item>
                                            <Grid.Item span={3}>
                                                <Button className='switch-btn' color='primary' size='small' onClick={() => changeStatus(item.imei, 'bpq_open')}>开</Button>
                                            </Grid.Item>
                                            <Grid.Item span={3}>
                                                <Button className='switch-btn' color='danger' size='small' onClick={() => changeStatus(item.imei, 'bpq_off')}>关</Button>
                                            </Grid.Item>
                                            <Grid.Item span={2}>
                                            </Grid.Item>
                                        </Grid>
                                    ))
                                }
                                {/* <Grid className='grid-content' columns={9} gap={8}>
                                    <Grid.Item span={3}>
                                        <Button color='default' fill='none'>
                                            水泵状态:
                                        </Button>
                                    </Grid.Item>
                                    <Grid.Item span={3}>
                                        <Button color='success' fill='none'>
                                            开
                                        </Button>
                                    </Grid.Item>
                                </Grid> */}
                            </>
                        ) : (null)
                    }
                </Space>

                {
                    inverter.length ? (<Divider />) : (null)
                }

                <Space direction='vertical' >
                    {
                        allValve.length ? (
                            <>
                                {
                                    allValve.map((item, index) => (
                                        <Grid className='grid-content' columns={12} gap={8} key={item.imei}>
                                            <Grid.Item span={4}>
                                                <Button color='default' fill='none'>
                                                    {item.name}:
                                                </Button>
                                            </Grid.Item>
                                            <Grid.Item span={3}>
                                                <Button className='switch-btn' color='primary' size='small' disabled={item.disabled} loading={item.loading} onClick={() => open(item.imei, index)}>全开</Button>
                                            </Grid.Item>
                                            <Grid.Item span={3}>
                                                <Button className='switch-btn' color='danger' size='small' disabled={item.disabled} loading={item.loading} onClick={() => close(item.imei, index)}>关</Button>
                                            </Grid.Item>
                                            <Grid.Item span={2}>
                                                <div style={{ fontSize: '16px', lineHeight: '30px' }}>
                                                    {
                                                        item.dev_online.last_open !== -1 ? (
                                                            <>
                                                                <Timer timestamp={item.dev_online.last_open * 1000} />
                                                            </>
                                                        ) : (
                                                            <>--</>
                                                        )
                                                    }
                                                </div>
                                            </Grid.Item>
                                        </Grid>
                                    ))
                                }
                                <div style={{ margin: '10px' }}>注：由于水泵功率问题，现只能开一个区的阀门</div>
                            </>
                        ) : (
                            <Button
                                color='default'
                                fill='none'
                                loading={loading}
                                style={{ width: '90vw' }}
                                loadingText={'正在获取设备'}
                            >正在获取设备</Button>
                        )
                    }
                </Space>

                <Divider />

                <Space direction='vertical' style={{ padding: '0 12px', fontSize: '1.0rem' }} >
                    {
                        allValve.filter(item => (area === 0 ? item : item.region_id === area)).map(item => (
                            <Grid className='grid-content alignment' columns={6} gap={8} key={item.imei} >
                                <Grid.Item span={2}>
                                    <span>{`${item.region}-${item.name}`}</span>
                                </Grid.Item>
                                <Grid.Item>
                                    {
                                        JSON.stringify(item.dev_online) === "{}" ? '离线' :
                                            item.dev_online.percentage === 100 ?
                                                (<span style={{ color: 'var(--adm-color-success)' }}>全开</span>) :
                                                item.dev_online.percentage === 50 ? '半开' :
                                                    (<span style={{ color: 'var(--adm-color-secondary)' }}>关闭</span>)
                                    }
                                </Grid.Item>
                                <Grid.Item>
                                    <span> 电量：</span>
                                </Grid.Item>
                                <Grid.Item>
                                    <span>{`${item.dev_online.electric || '--'}%`}</span>
                                </Grid.Item>
                            </Grid>
                        ))
                    }
                </Space>

                {
                    allValve.length ? (<Divider />) : (null)
                }

                <Space direction='vertical' style={{ padding: '0 12px', fontSize: '1.0rem', lineHeight: '22px' }} size="large">
                    {
                        lora ? (
                            <Grid className='grid-content' columns={6} gap={8} >
                                <Grid.Item span={2}>
                                    <span>土地湿度：</span>
                                </Grid.Item>
                                <Grid.Item span={2}>
                                    {lora.humidity ? lora.humidity + ' %' : '-'}
                                </Grid.Item>
                                <Grid.Item span={2}>
                                    {lora.time}
                                </Grid.Item>
                            </Grid>
                        ) : (null)
                    }
                    <Grid className='grid-content' columns={6} gap={8} >
                        <Grid.Item span={2}>
                            <span>变频器频率：</span>
                        </Grid.Item>
                        <Grid.Item span={2}>
                            {record.bpq_pl && (record.bpq_pl[0]?.value ? record.bpq_pl[0]?.value + ' Hz' : '-')}
                        </Grid.Item>
                        <Grid.Item span={2}>
                            {record.bpq_pl && record.bpq_pl[0]?.time}
                        </Grid.Item>
                    </Grid>
                    <Grid className='grid-content' columns={6} gap={8} >
                        <Grid.Item span={2}>
                            <span>管道压力：</span>
                        </Grid.Item>
                        <Grid.Item span={2}>
                            {record.bpq_gdyl && (record.bpq_gdyl[0]?.value ? record.bpq_gdyl[0]?.value + ' Kg' : '-')}
                        </Grid.Item>
                        <Grid.Item span={2}>
                            {record.bpq_gdyl && record.bpq_gdyl[0]?.time}
                        </Grid.Item>
                    </Grid>
                    <Grid className='grid-content' columns={6} gap={8} >
                        <Grid.Item span={2}>
                            <span>潜水泵：</span>
                        </Grid.Item>
                        <Grid.Item span={2}>
                            {qsb_state && (qsb_state[0]?.value === 1 ? '开' : qsb_state[0]?.value === 0 ? '关' : '-')}
                        </Grid.Item>
                        <Grid.Item span={2}>
                            {qsb_state && qsb_state[0]?.time}
                        </Grid.Item>
                    </Grid>
                </Space>

                <Button
                    color='primary'
                    className='refesh-btn'
                    loading={loading}
                    loadingText={'正在更新状态'}
                    onClick={this.fetch}
                >刷 新</Button>

            </div >

        )
    }
}

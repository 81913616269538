import request from "../request"

const getlist = () => request.get('/region/getlist')

const getDevice = (queryParams) => request.get('/dev/getlist2', { params: { ...queryParams } })

/**
 * mqtt指令集，开关等
 * @param {*} queryParams 
 * @returns 
 */
const comandctrl = (queryParams) => request.get('/dev/comand/comand', { params: { ...queryParams } })


/**
 * 阀门开关控制
 * @param {*} imei 
 * @returns 
 */
const open = (imei) => request.get('/open', { params: { imei } })
const half = (imei) => request.get('/half', { params: { imei } })
const close = (imei) => request.get('/close', { params: { imei } })


/**
 * 变频器信息
 * @param {*} queryParams 
 * @returns 
 */
const comandmessage = (queryParams) => request.get('/dev/comand/bpq/message', { params: { ...queryParams } })

/**
 * 获取变频器频率
 * @param {*} imei 
 * @returns 
 */
const comandpl = (imei) => request.get(`/dev/states`, { params: { imei, comand_name: 'bpq_pl', key: 'cnmd-lunxun' } })
const comandgdyl = (imei) => request.get(`/dev/states`, { params: { imei, comand_name: 'bpq_gdyl', key: 'cnmd-lunxun' } })
const comandgqsb = (imei) => request.get(`/dev/states`, { params: { imei, comand_name: 'qsb_state', key: 'cnmd-lunxun' } })
/**
 * 潜水泵状态
 * @param {*} queryParams 
 * @returns 
 */
const comandstate = (queryParams) => request.get('/dev/comand/qsb/state', { params: { ...queryParams } })

/**
 * 最新一条土壤信息
 * @param {*} queryParams 
 * @returns 
 */
const comandlora = (queryParams) => request.get('/dev/comand/lora/last', { params: { ...queryParams } })


const seeding = {
    getlist,
    getDevice,
    comandctrl,
    comandmessage,
    comandstate,
    comandlora,
    comandpl,
    comandgdyl,
    comandgqsb,
    open,
    half,
    close
}

export default seeding
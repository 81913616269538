import axios from 'axios';

let baseURL = '';
switch (process.env.NODE_ENV) {
    case 'production': // 生产
        baseURL = 'http://testapi.yn-ag.cn/api'
        break;
    case 'test': // 测试
        baseURL = 'http://api.yn-ag.cn/api'
        break;
    default:  // 开发
        baseURL = '/api'
        break;
}



//设置axios基础路径
const request = axios.create({
    baseURL,
    timeout: 1000 * 30,
})


// 请求拦截器
request.interceptors.request.use(config => {
    const { token } = JSON.parse(sessionStorage.getItem("user")) || { token: null }
    if (token) {
        config.headers['token'] = token
    }
    return config
}, error => {
    return error;
})

// 响应拦截器
request.interceptors.response.use(
    response => {
        if (response.status === 200) {
            const code = Number(response.data.code)
            if (code === 0) {
                return response.data;
            }
            // 后台返回时，没有code值，但有data数据
            if (isNaN(code) && response.data.length > 0) {
                return response.data;
            }

            return Promise.reject(response.data)
        } else {

            return Promise.reject(response.data)
        }
    },
    error => {
        return Promise.reject(error)
    }
)

/**
 * 配置上传
 * @param {*} url 
 * @param {*} param1 
 * @param {*} fileName 
 * @returns 
 */
request.uploadFile = (url, file, fileName = 'files') => {
    let formData = new FormData();
    formData.append(fileName, new Blob([file]))
    return request({
        url,
        headers: {
            'Content-Type': 'multipart/form-data',
        },
        transformRequest: data => data,
        method: 'POST',
        data: formData
    })
}
// 最后把封装好的axios导出
export default request
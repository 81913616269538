import React from 'react';
import { Routes, Route } from 'react-router-dom';
import RiceseedView from './riceseed';
import Mineiew from './mine';
import SoilsensorView from './soilsensor';


export default function RouteView() {
    return (
        <Routes>
            <Route exact path="/" element={<RiceseedView />} />
            <Route exact path="/soilsensor" element={<SoilsensorView />} />
            <Route exact path="/mine" element={<Mineiew />} />
        </Routes>
    )
}
import React from 'react'
import { BrowserRouter, Routes, Route } from 'react-router-dom';
import Layout from '../pages/layout';
import Login from '../pages/login';
import Auth from './auth';

export default function RouteView() {

    return (
        <BrowserRouter>
            <Routes>
                <Route path="/*" element={<Auth><Layout /></Auth>}></Route>
                <Route path="/login" element={<Login />} />
            </Routes>
        </BrowserRouter>
    )
}

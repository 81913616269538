import React from 'react';
import { TabBar } from 'antd-mobile';
import { AppOutline, UserOutline, TransportQRcodeOutline } from 'antd-mobile-icons';
import { useNavigate, useLocation } from 'react-router-dom';

export default function TabBarBottom() {
    const navigate = useNavigate()
        , { pathname } = useLocation()
        , setRouteActive = (key) => {
            const { title } = tabs.find(tab => tab.key === key)
            navigate(key, { state: { title } }, { replace: true })
        }
        , tabs = [
            {
                key: '/',
                title: '秧田',
                icon: <AppOutline />
            },
            {
                key: '/soilsensor',
                title: '土壤传感器',
                icon: <TransportQRcodeOutline />
            },
            {
                key: '/mine',
                title: '我',
                icon: <UserOutline />
            }
        ];
    return (
        <TabBar
            activeKey={pathname}
            onChange={value => setRouteActive(value)}
        >
            {
                tabs.map(item => (
                    <TabBar.Item
                        key={item.key}
                        icon={item.icon}
                        title={item.title}
                    />
                )
                )
            }
        </TabBar>
    )
}

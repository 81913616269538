import { configureStore } from '@reduxjs/toolkit';
import user from './modules/user';
/**
 * 创建store
 * 
 */

export default configureStore({
    reducer: {
        user
    }
})
import React, { useState, useEffect } from 'react';

export default function Timer({ timestamp, stopTimer = false }) {
    const [time, setTime] = useState(Date.now() - timestamp);

    const [isRunning, setIsRunning] = useState(true);

    useEffect(() => {
        if (isRunning) {
            const intervalId = setInterval(() => {
                setTime(Date.now() - timestamp);
            }, 1000);
            return () => clearInterval(intervalId);
        }
    }, [timestamp, isRunning]);

    useEffect(() => {
        if (stopTimer) {
            setIsRunning(false);
        } else {
            setIsRunning(true);
        }
    }, [stopTimer]);

    const displayTime = () => {
        const seconds = Math.floor(time / 1000) % 60;
        const minutes = Math.floor(time / 1000 / 60) % 60;
        const hours = Math.floor(time / 1000 / 60 / 60);
        return `${hours.toString().padStart(2, '0')}:${minutes.toString().padStart(2, '0')}:${seconds.toString().padStart(2, '0')}`;
    }

    return (
        <div>{displayTime()}</div>
    );
}
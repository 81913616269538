import React, { Component } from 'react';
import RouteView from './route';
import './App.less';

export default class App extends Component {
    render() {
        return (
            <RouteView></RouteView>
        )
    }
}
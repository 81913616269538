import React from 'react';
import RouteView from './route';
import './index.less';
import TabBarBottom from '../../components/TabBar';

export default function LayoutView() {
    return (
        <div className="page">
            <div className="page-body">
                <RouteView />
            </div>
            <div className="page-bottom">
                <TabBarBottom />
            </div>
        </div>
    )
}

import React, { useState, useEffect } from 'react';
import { Input, Button, Form, Image, Toast } from 'antd-mobile/es';
import Logo from "../../assets/image/logo.png";
import { login } from '../../store/modules/user';
import { useNavigate } from "react-router-dom";
import { useDispatch, useSelector } from 'react-redux';

const Login = () => {
    const [form] = Form.useForm()
        , dispatch = useDispatch()
        , { token } = useSelector(state => state.user)
        , navigate = useNavigate()

    useEffect(() => {
        if (token) {
            // 监听 state.user 变化
            Toast.show({
                icon: 'success',
                content: '登录成功',
            })
            navigate('/')
        }
    }, [token, navigate])

    const handleLogin = () => {
        const values = form.getFieldsValue()
        console.log(values);
        dispatch(login({ phone: values.login_name, pwd: values.pwd }))
    };

    return (
        <div>

            <div >
                <Image src={Logo} width={120} height={120} style={{ margin: '50px auto' }}></Image>
            </div>


            <Form
                layout='horizontal'
                form={form}
                onFinish={handleLogin}
                footer={
                    <Button block type='submit' color='primary' size='large'>
                        登 录
                    </Button>
                }
            >
                <Form.Item
                    name='login_name'
                    label='账号'
                    rules={[{ required: true, message: '请输入账号' }]}
                >
                    <Input placeholder='请输入账号' />
                </Form.Item>

                <Form.Item
                    name='pwd'
                    label='密码'
                    rules={[{ required: true, message: '请输入密码' }]}
                >
                    <Input type={'password'} placeholder='请输入密码' />
                </Form.Item>
            </Form>
        </div>
    );
};

export default Login;
import { createSlice, createAsyncThunk } from "@reduxjs/toolkit";
import { sys } from '../../service';

export const login = createAsyncThunk('login', async ({ phone, pwd }) => {
    const { data: { token, user_info } } = await sys.login(phone, pwd)
    const userStore = {
        info: user_info,
        token: token
    }
    await saveUser(userStore)
    return userStore
})


const saveUser = (userStore) => {
    return new Promise((resolve) => {
        sessionStorage.setItem("user", JSON.stringify(userStore))
        resolve()
    })
}


const user = createSlice({
    // 命名空间， name作为action type 的前缀
    name: 'user',
    // 初始化状态
    initialState: JSON.parse(sessionStorage.getItem("user")) || {
        info: {},
        token: null
    },
    /**
     * 1. 更新reducer更新状态函数
     * 2. 组件中dispatch使用的action函数
     *    内置了immutable.js插件
     */
    reducers: {
        setUser(state, { payload }) {
            const { info, token } = payload
            state.info = info
            state.token = token
        },
        removeUser(state) {
            state.info = {}
            state.token = null
        },
        toggle(state, { payload }) {
            state.token = payload.token
            saveUser(state)
        }
    },
    // 可以额外的触发其他slice中的数据关联改变
    extraReducers: {
        [login.fulfilled](state, { payload }) {
            state.info = payload.info
            state.token = payload.token
            console.log('fulfilled', state, payload)

        },
        [login.rejected](state, err) {
            // debugger
            console.log('rejected', state, err)
        },
        [login.pending](state) {
            // debugger
            console.log('pending', state)
        }
    }
})

// 导出action函数
export const { setUser, removeUser, toggle } = user.actions


// 导出  reducer ， 创建store
export default user.reducer
import React, { useState, useEffect } from 'react';
import { List, Card, Button, Popup, DatePicker, NavBar } from 'antd-mobile/es'
import { getFormatDateTime } from '../../../utils/utils'
import { soil } from '../../../service'
const now = new Date()

export default function DetailsView(props) {
    const { imei, address, name, open } = props
        , [data, setData] = useState([])
        , [humidity, setHumidity] = useState('')
        , [visible, setVisible] = useState(false)
        , [date, setDate] = useState(getFormatDateTime(+new Date()).split(' ')[0])
        , [time, setTime] = useState('')
        , [updateTime, setUpdateTime] = useState('')
        , [showNew, setShowNew] = useState(true)


    useEffect(() => {
        async function fetchData() {
            await fetch()
        }
        fetchData()
        judgeDate()
    }, [imei, open, date])

    const fetch = async () => {
        const { data } = await soil.getSoilData({ imei: imei, address, day: date })
        const list = data.list.filter(_ => {
            return _.type === '土壤湿度 %'
        })
        setData(list)
        setHumidity(list[0].value)
        setTime(list[0].time.split(' ')[1])
        setUpdateTime(getFormatDateTime(coverTime(list[0].time)))
    }

    const coverTime = (timeString) => {
        return new Date(new Date(timeString).getTime() + 30 * 60 * 1000);
    }

    const judgeDate = () => {
        let todayDate = getFormatDateTime(+new Date()).split(' ')[0]
        setShowNew(date === todayDate);
    }

    const onClose = () => {
        console.log('onClose...');
        props.onClose()
    }


    return open ? (
        <Popup
            visible={open}
            onClose={onClose}
            position='right'
            bodyStyle={{ width: '100vw' }}
            className="popup-page"
        >
            <div className='header'>
                <NavBar onBack={onClose}>{name}</NavBar>
            </div>
            <div className="App">
                <List style={{ marginBottom: '15px' }}>
                    <List.Item extra='请选择' onClick={() => { setVisible(true) }}>{date}</List.Item>
                    {showNew ? (<List.Item extra={humidity}>最新湿度（{time}）</List.Item>) : null}
                </List>
                <DatePicker
                    visible={visible}
                    defaultValue={now}
                    max={now}
                    onClose={() => {
                        setVisible(false)
                    }}
                    onConfirm={v => {
                        console.log(v);
                        let d = getFormatDateTime(v).split(' ')[0]
                        setDate(d)
                    }}
                >
                </DatePicker>
                <div style={{ maxHeight: 'calc(100vh - 200px)', overflowY: 'scroll' }}>
                    <List style={{ height: '100%' }} header={'土壤湿度 %' + (showNew ? `  （下次更新:${updateTime}）` : '')} mode="card">
                        {
                            data.map(item => <List.Item extra={item.value} key={item.time}>{item.time}</List.Item>)
                        }
                    </List>
                </div>
                <div style={{ position: 'absolute', bottom: 5, width: '100%' }}>
                    <Card>
                        <Button block color='primary' size='large' onClick={() => { fetch() }}>
                            刷 新
                        </Button>
                    </Card>
                </div>
            </div>
        </Popup>
    ) : (null)
}
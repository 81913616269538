/**
 * 时间格式化
 * @param t 时间戳
 * @returns '2022-12-12 16:33:39'
 */
export function getFormatDateTime(t = null) {
    let date = t ? new Date(t) : new Date();
    let year = date.getFullYear();
    let month = date.getMonth() + 1 > 9 ? date.getMonth() + 1 : `0${date.getMonth() + 1}`;
    let day = date.getDate() > 9 ? date.getDate() : `0${date.getDate()}`;
    let hour = date.getHours();
    let minute = date.getMinutes();
    let second = date.getSeconds();
    return [year, '-', month, '-', day, ' ', hour, ':', minute, ':', second].join('');
}

export const withParams = (Component, useParams = () => { }, useLocation = () => { }) => {
    return props => <Component {...props} params={useParams()} location={useLocation()} />
}

import request from "../request"

/**
 * 登录
 * @param {*} "login_name": "string",
 * @param {*}   "pwd": "string" 
 * @returns 
 */
const login = (phone, password) => request.post('/seeding/login', { login_name: phone, pwd: password })


/**
 * 退出登录
 */
const logout = () => request.get('/seeding/logout')


const sys = {
    login,
    logout,
}

export default sys
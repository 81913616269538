import React from 'react'
import { Navigate, useLocation } from 'react-router-dom'
import { useSelector } from 'react-redux';
const AuthRouter = ({ children }) => {
    const { token } = useSelector(state => state.user)
        , location = useLocation()

    console.log(token);
    if (token) {
        return children
    } else {
        return <Navigate to='/login' replace state={{ from: location }}></Navigate>
    }
}
export default AuthRouter
import React, { useState, useEffect } from 'react'
import { removeUser } from '../../../store/modules/user';
import { Button, Avatar } from 'antd-mobile/es'
import { useNavigate } from 'react-router-dom';
import { useDispatch, useSelector } from 'react-redux';
import "./index.less"
import avatar from './image/avatar.png'

export default function MineView() {
    const dispatch = useDispatch()
        , navigate = useNavigate()
        , userInfo = useSelector(state => state.user.info)

    useEffect(() => {
        async function fetchData() {
            console.log('effff');
        }
        fetchData()
    }, [])

    const logout = () => {
        dispatch(removeUser())
        navigate('/login', { replace: true })
    }

    return (
        <div>
            <div className='userinfo'>
                <Avatar src={avatar} style={{ '--size': '64px', marginLeft: '25px' }} />
                <div className='username'>
                    {userInfo.name}
                </div>
            </div>
            <Button color='primary' className='logout-btn' onClick={logout}>退出登录</Button>
        </div>
    )
}
